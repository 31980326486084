import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({data}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
      <SEO title={frontmatter.title} />
            <div className="">
                <h1 className="mb-0">{frontmatter.title}</h1>
                <p className="text-sm">{frontmatter.date}</p>
                <div
                className=""
                dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
</Layout>
  
}
export const pageQuery = graphql`
query GetFile ($nodeID: String!) {
    markdownRemark(id: {eq: $nodeID}) {
      html
      frontmatter {
        date(formatString: "DD-MM-YYYY")
        title
      }
    }
  }  
`